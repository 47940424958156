/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    img: "img",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Tinnitus is ringing, buzzing, or other noises experienced in the ears. The sounds pose major disruptions in people’s lives and indicate possible ear damage and hearing loss. Fortunately, hear.com experts understand your symptoms and can direct you to a hearing specialist for relief. Call 647-492-5458 for more information or request a callback through our online form."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Tinnitus can occur in one ear (left or right) or both. The noises originate in the head and ear canal rather than outside the body. There are about 200 health disorders that could cause tinnitus sounds. One of the most frequent reasons for ear ringing is hearing loss. The damage could be caused by age related factors, noise factors or both. Speaking with a hear.com expert can help you understand if you have hearing loss that may have gone unnoticed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The sounds experienced by people vary in volume, intensity and type. Some noises sound like high-pitched ringing while others sound like an airplane engine. Other types of noises are similar to the sounds of wind blowing, a rainstorm, or a tea kettle whistling. People experience intermittent or constant sounds. Whatever type of sound experienced, the noise disrupts a person’s life. A person may never have relief from constant ear noise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "About forty percent of the population will experience unpleasant ear ringing at some point in their lifetime. Ten to twenty percent will experience chronic tinnitus (a duration of over three months). Symptoms most commonly occur in people over the age of 40. However, people of all ages are susceptible to some form of disruptive and persistent ear ringing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Carefully read the information below so that you can identify the symptoms, causes, and available treatments. Please call hear.com to speak with an expert about your questions. We will explain tinnitus in further depth, discuss your symptoms, and, if you like, set an appointment for you with a hearing specialist. Call us today!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#symptoms",
    "aria-label": "symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Symptoms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The main symptom of tinnitus is hearing different noises when none are actually present. These noises may sound like whistling, crackling, rustling, or ringing. This symptom may come and go or be present all the time, affecting one ear or both. Other symptoms include sleeping difficulties, poor concentration, headaches, and tension in the neck."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ear-noise-sounds-like",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ear-noise-sounds-like",
    "aria-label": "ear noise sounds like permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ear Noise Sounds Like:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/v1681139582/assets/na/seo-website/ear-noice.png",
    alt: "img",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosis",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosis",
    "aria-label": "diagnosis permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosis"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Common examinations:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Examine the throat, nose and ears"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Subjective hearing test"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Analyse the noise in the ear; test the noise emission of the inner ear (otoacoustic emissions)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Test for any balance disorders"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Objective hearing test (auditory brainstem response)"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "There are different methods to diagnose tinnitus:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Blood analysis"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "MRT of the skull (magnetic resonance tomography)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "CT of the skull (computer tomography)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Examine the neck vertebrae and the jaw"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "What is a subjective hearing test?")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With the patient’s participation a subjective hearing test checks the perception of sound in the ears. Seated in a soundproofed room, various sounds in a variety of frequency domains fed into the patient’s ears via headphones. The patient indicates to the specialist the instant the sound becomes audible. This allows the ENT doctor to firstly establish any hearing loss and then concentrate on the tinnitus."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "tinnitus-and-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tinnitus-and-hearing-aids",
    "aria-label": "tinnitus and hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tinnitus and Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "More than fifty percent of tinnitus sufferers also have a loss of hearing. Using a hearing aid can significantly increase the quality of life and health for those with hearing loss and ear ringing. Hearing aids compensate for the loss, enabling people to hear external sounds again and retraining the brain to hear sounds."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When hearing loss occurs, the brain may lose its ability to process certain sounds and frequencies. Ear ringing may occur as the brain changes in response to hearing loss. Therefore, as hearing aids help restore the brain’s ability to process certain sounds and frequencies, ear ringing may be reduced or alleviated altogether. For more information about how hearing aids provide relief for ear ringing, contact a hear.com expert at 647-492-5458."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "hear.com partners with hearing specialists throughout the country to conduct hearing tests and determine which hearing aids are best for each client. The Partner Provider will determine if you are a candidate for the combination hearing aid and tinnitus noiser. More and more people treated for ear noise choose to use a hearing aid with an integrated tinnitus noiser. This addition works on the principle of distraction, substituting negative noises with positive ones. The added technology in the hearing aid generates pleasant noises, which the user can select accordingly. The hearing aid-noiser combination is not known as a tinnitus cure, but as an ear ringing management instrument."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/level-tinnitus.png",
    alt: "Level of tinnitus",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "tinnitus-and-hearing-difficulties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tinnitus-and-hearing-difficulties",
    "aria-label": "tinnitus and hearing difficulties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tinnitus and Hearing Difficulties"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with ear ringing often have hearing difficulties which reduce quality of life and health significantly. A hearing test used in a research study of ear noise therapy revealed 95% of tinnitus patients had damaged hearing. 89% of the patients had hearing impairment in both ears. Only 5% had no hearing impairment. However, one cannot conclude that hearing loss or difficulties result in ear ringing. Nonetheless, damaged hearing is considered to be a direct cause for ear ringing symptoms. Around 80% suffering hearing damage for a long period have chronic ear ringing. Hearing aids can be an appropriate means for treating hearing difficulties and at the same time prevent ear ringing. Please request a call with a hear.com expert to learn more about our hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The Tinnitus-Noiser")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The noise from the noiser is softer than the ear ringing. This enables the brain to re-learn how to focus on noises in the external environment and minimizes or completely eradicates the fixation on the ear noise. The therapy helps the brain become accustomed to sounds and after a certain time, fade them out. Leading companies in the hearing aid sector have specialized in these devices due to the high number people experiencing ear ringing. Renowned manufacturers such as Phonak offer hearing aids with integrated tinnitus noisers to reduce ear ringing and increase the quality of your health. Tinnitus relief is just a few steps away! Call us today for a risk-free trial!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Forms of treatment for tinnitus relief")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The treatment and therapy varies according to what has actually caused the tinnitus. They can be acoustic stimulation, behavioral therapy approaches, medication, magnetic and electrical brain stimulation and physiotherapy. In acute cases Vitamin E preparations, magnesium, glucocorticoids such as cortisone, local anesthetics administered intravenously such as procaine and active ingredients to improve blood circulation (e.g. pentoxifylline). According to the cause and severity of the tinnitus the treatment and therapy can be in tablet form or intravenous drip."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "forms-of-tinnitus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#forms-of-tinnitus",
    "aria-label": "forms of tinnitus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Forms of Tinnitus"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Acute and chronic tinnitus")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Acute tinnitus occurs when an unpleasant ear noise suddenly appears and remains for up to three months. In the case of chronic tinnitus the ear noises remain longer than the three month and may remain perceptible in the long term. If life quality is not impaired the sound is not compensated. If it is pathologically impaired the tinnitus is compensated for. Acute tinnitus is usually healed spontaneously or the patient recovers from the symptoms. The longer the ear noise remains, however, the higher the possibility of chronic illness."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/tinnitus-dont-panic.png",
    alt: "dont panic when your ear is ringing",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Subjective tinnitus")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Subjective tinnitus refers to an ear noise that can only be heard by that person. Most tinnitus cases are subjective. Subjective tinnitus is caused by a mistake in processing at the points of sound transmission and sound perception. There can be a dysfunction in the area of the middle ear right down to the cortex. For instance, ear noises occur through insufficient absorption of high sound pressure by the muscle in the middle ear.\nThere are a number of possible causes of subjective ear ringing. Often ear noises become audible when a foreign substance enters or during a middle ear infection. A number of illnesses or stressors can trigger subjective tinnitus. Sudden deafness or hearing loss is generally accepted as a cause of ear ringing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "In summary: subjective tinnitus at a glance")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Foreign substances in the auditory canal (e.g. a build up of ear wax)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "An ear infection (otitis media or externa)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Viral and bacterial infections (e.g. borreliosis)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Middle ear diseases disturbing sound transmission e.g. otosclerosis"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Noise trauma (acute or chronic)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Sudden deafness"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Diving accidents"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Autoimmune disease of the inner ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ototoxic substances"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A tumor on the hearing nerves (acoustic neurinoma)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Difficulty hearing (hypoacusis)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Stress"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Problems with the neck vertebrae or in the teeth and jaw area trigger or increase the symptoms"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Objective tinnitus")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If an ear noise can be heard and measured by the person examining it is an objective tinnitus. By comparison with subjective tinnitus, objective tinnitus is rather rare."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "In summary: objective tinnitus at a glance")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Breathing noises"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Current noises of arteries"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tension of the middle ear muscle"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Movements in the opening of the ear trumpet or acute functional disorder of the Eustachian tube."), "\n"), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "reasons-for-its-emergence",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#reasons-for-its-emergence",
    "aria-label": "reasons for its emergence permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Reasons for Its Emergence"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The reasons for the emergence of tinnitus have only been partially explained. One of the possible causes is the effect of noise or certain medicaments on the auditory nerve. In healthy ears, the nerves spontaneously produce electrical impulses, so-called spontaneous activity. This changes when the noise meets the ear. Through this encrypted information is sent to the ear. If the auditory nerve is damaged it has the effect of spontaneous activity. This can reduce or change in time. It is assumed that a deviation of spontaneous activity results in subjective ear ringing. Tinnitus can also, however, take place in the brain."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In this case it is a so-called central tinnitus, a defective transmission of information, which occurs on the transmission path between the hair cells in the ear and the brain. One of the major causes of ear ringing continues to be diagnosed as stress. Stress induces a great number of physical reactions. The body excretes more of the hormone cortisol, which results in a constriction of the blood vessels reducing blood circulation. This reduction has a particular effect on the small blood vessels, the capillaries, and can lead to closure of the blood vessels in the inner ear. It is not yet known precisely how the reactions triggered by stress cause ear ringing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "tinnitus-in-old-age",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tinnitus-in-old-age",
    "aria-label": "tinnitus in old age permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tinnitus in Old Age"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Around 7 million people in Canada have hearing difficulties – brought about by various causes, such as specific diseases, strong medication or sudden deafness. Current figures, estimate 10 – 15% of the population have tinnitus (for 2014). This illness cannot, however, be entirely placed into one age bracket. Children can also have ear noises. Nonetheless, the majority of people with tinnitus are 50 or older. Furthermore, occurrences are diagnosed more frequently in women than men. Recent years have also seen an increasing frequency of tinnitus in youths and young adults. This increase in young tinnitus patients is caused, above all, by increasing contact with loud noise levels through music venues and the use of headphones and MP3 players for example. hear.com is there to advise and help you on ear noise (tinnitus) and give you the means to become its master with the Tinnitus Noiser!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "preventing-tinnitus",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#preventing-tinnitus",
    "aria-label": "preventing tinnitus permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Preventing Tinnitus"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The causes of tinnitus are many. But there is good news! The risk of occurrence can be reduced and there are preventative measures to take to avoid this illness. Fundamentally one must avoid the danger of damaging the ear through loud noises. Wear enough protection for your hearing against loud noises. Furthermore, one should reduce stress as much as possible. To this end, manage your time and allow for periods of relaxation and exercise. Recognize stress in your life, find healthy ways to cope with stress, have a support network, and eat balanced and healthy meals. These choices can lead to more energy and a more efficient everyday life. One should most certainly go to an ENT specialist with acute ear noise for a short-term therapy – usually through the use of medicaments – to avoid long-term problems."), "\n", React.createElement(ButtonCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sources:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "https://tinnitus.org.uk/understanding-tinnitus/living-with-tinnitus/tinnitus-and-hearing-aids/",
    className: "c-md-a"
  }, "Tinnitus.org.uk")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "https://www.asha.org/public/hearing/Tinnitus/",
    className: "c-md-a"
  }, "Asha.org")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "https://www.nidcd.nih.gov/health/tinnitus",
    className: "c-md-a"
  }, "Nidcd.nih.gov")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "https://www.mayoclinic.org/diseases-conditions/tinnitus/symptoms-causes/syc-20350156",
    className: "c-md-a"
  }, "Mayoclinic")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
